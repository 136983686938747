import { mapState } from 'vuex'
import config from '@/../config'
import axios from 'axios/index'
import $ from 'jquery'

export default {
  name: 'klarna',
  data () {
    return {
      msg: '',
      klarnaLoading: false,
      klarnaUrl: null,
      klarnaBlock: null,
      lockAppend: true,
      order: {}
    }
  },
  props: [
    'orderId',
    'order',
    'sspayMode'
  ],
  computed: {
    ...mapState({
      apiHost: (state) => state.apiHost,
      regData: (state) => state.regData,
      integrationHost: (state) => state.integrationHost,
      currency: (state) => ({...state.currency.allCurrency[state.currency.selected]})
    }),
    httpClient: function () {
      return axios.create({
        // baseURL: config.apiHost + config.prefix
      })
    }
  },
  components: {},
  methods: {
    send_post: function (module, data, apiHost) {
      apiHost = apiHost || false
      let prefix = apiHost ? config.integrationPrefix : config.prefix
      let host = apiHost || this.apiHost
      this.debugLog('send_post: ' + module, {'host': host, 'module': module, 'data': data})
      // return Vue.http.post(host + prefix + module + (apiHost ? '/' : ''), data, this.requestOptions)
      var qs = require('qs')
      return this.httpClient.post(host + prefix + module + (apiHost ? '/' : ''), qs.stringify(data), {
        withCredentials: true,
        headers: {'Content-Type': 'application/x-www-form-urlencoded', 'Access-Control-Allow-Credentials': true}
      })
    },
    send_get: function (module, data, apiHost) {
      apiHost = apiHost || false
      let prefix = apiHost ? config.integrationPrefix : config.prefix
      let host = apiHost || this.apiHost
      this.debugLog('send_get: ' + module, {'host': host, 'module': module, 'data': data})
      return this.httpClient.get(host + prefix + module + (apiHost ? '/' : ''), {
        ...data,
        withCredentials: true
      })
      // return Vue.http.get(host + prefix + module + (apiHost ? '/' : ''), data)
    },
    debugLog: function (one, two) {
    },
    generateKlarnaFrame: function () {
      let self = this
      $('#klarna-checkout-container').remove()
      this.send_get(config.checkout.getKlarna + (this.sspayMode ? '-intg' : ''), {
        params: {
          order_id: this.orderId
        }
      }).then((resp) => {
        if (resp.data.result) {
          self.klarnaUrl = resp.data.paymentMethods.Klarna3
          self.klarnaBlock = resp.data.paymentBlock.Klarna3
          self.appendFrame()
          // this.$forceUpdate()
        }
      })
    },
    appendFrame: function cleanPromodo () {
      let snippet = this.klarnaBlock
      this.klarnaLoading = false
      if (this.lockAppend) {
        this.lockAppend = false
        $('#klarnaIframe').append($(snippet))
      }
    },
    regenerateKlarnaFrame: function () {
      this.lockAppend = true
      this.klarnaUrl = ''
      this.klarnaBlock = ''
      this.generateKlarnaFrame()
    }
  },
  mounted: function () {
    this.generateKlarnaFrame()
  },
  watch: {
    currency: {
      handler: function (changed) {
        this.klarnaLoading = true
        this.regenerateKlarnaFrame()
      },
      deep: true
    }
    // order: {
    //   handler: function (changed) {
    //     console.log('order: ', this.order)
    //   },
    //   deep: true
    // }
  },
  created: function () {
  }
}
